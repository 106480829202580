import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output, PLATFORM_ID } from '@angular/core';
import { ID } from '@datorama/akita';
import { CartHeader, CartItem, FavoriteItem, FavoriteList } from '@lobos/common';
import {
  AuthService,
  CartItemQuery,
  CartService,
  CreateCartItemInterface,
  FavoriteItemQuery,
  FavoriteListQuery,
  FavoriteService,
  UrlHelperService,
} from '@lobos/library-v2';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SimpleModalService } from 'ngx-simple-modal';
import { combineLatest, merge, Observable, of, Subject } from 'rxjs';
import { debounceTime, filter, first, map, mergeMap, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AddEditFavoriteModalComponent } from '../../../../features/profile/profile-features/favorites/favorites-components/add-edit-favorite-modal/add-edit-favorite-modal.component';
import { GelaCartItem } from '../../../../services/cart/model/gela-cart-item.model';
import { GelaFavoriteItem } from '../../../../services/favorite/model/gela-favorite-item.model';
import { LoginModalComponent } from '../../../login-modal/login-modal.component';
import { Router } from '@angular/router';
import { GelaFavoriteList } from '../../../../interfaces/favorites/gela-favorite-list.interface';
import { orderBy } from 'lodash';

@UntilDestroy()
@Component({
  selector: 'app-favorites-flyout',
  templateUrl: './favorites-flyout.component.html',
  styleUrls: ['./favorites-flyout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavoritesFlyoutComponent {
  @Input()
  public mobile: boolean = false;

  @Output()
  public closed: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isLoading$: Observable<boolean> = combineLatest([
    this.favoriteItemQuery.selectLoading(),
    this.favoriteListQuery.selectLoading(),
    this.cartItemQuery.selectLoading(),
  ]).pipe(map(([l1, l2, l3]: [boolean, boolean, boolean]) => l1 || l2 || l3));

  public isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;
  public showFlyout: boolean = false;

  activeFavoriteList$: Observable<FavoriteList | undefined> = this.favoriteListQuery.selectActive();
  activeFavoriteItems$: Observable<GelaFavoriteItem[]> = this.favoriteListQuery.selectActiveId().pipe(
    filter((id: ID | null | undefined) => !!id),
    switchMap((id: ID | null | undefined) => this.favoriteItemQuery.selectFavoriteItems(id!)),
    shareReplay(1),
  );
  favoriteLists$: Observable<{ key: string; value: string }[]> = this.favoriteListQuery.selectAll().pipe(
    map((favorites: FavoriteList[]) =>
      favorites
        .filter((favorite: FavoriteList) => {
          // only show favoriteList that are not readonly
          const favoriteShares = (favorite as GelaFavoriteList).shares;
          if (favoriteShares?.length && favoriteShares[0].readOnly) {
            return;
          }
          return favorite;
        })
        .map((favorite: FavoriteList) => ({
          key: favorite.gListID.toString(),
          value: favorite.sListname,
        })),
    ),
    shareReplay(1),
  );

  constructor(
    private favoriteListQuery: FavoriteListQuery,
    private favoriteItemQuery: FavoriteItemQuery<GelaFavoriteItem>,
    private favoriteService: FavoriteService<FavoriteList, FavoriteItem>,
    private cartService: CartService<CartHeader, CartItem, CreateCartItemInterface>,
    private modal: SimpleModalService,
    private authService: AuthService,
    private cartItemQuery: CartItemQuery<GelaCartItem>,
    private router: Router,
    private urlHelper: UrlHelperService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {}

  open(): void {
    this.showFlyout = true;
    this.closed.emit(false);
  }

  close(): void {
    this.showFlyout = false;
    this.closed.emit(true);
  }

  setActiveFavoriteList(favoriteListId: string | number) {
    this.favoriteService.getFavoriteItems(favoriteListId).pipe(take(1), untilDestroyed(this)).subscribe();
    this.favoriteService.setActiveFavoriteId(favoriteListId);
  }

  addFavoriteList(): void {
    this.modal.addModal(AddEditFavoriteModalComponent);
  }

  addAllToCart() {
    // end convert stream
    const end = new Subject<boolean>();
    const end$ = of(undefined).pipe(
      tap(() => {
        end.next(true);
        end.complete();
      }),
    );

    // convert favorite items to cart items
    this.activeFavoriteItems$
      .pipe(
        switchMap((favorites: FavoriteItem[]) =>
          merge([
            ...favorites
              .filter((f: FavoriteItem) => f.decQuantity > 0)
              .map((favoriteItem: FavoriteItem) =>
                this.cartService.createCartItem({
                  decQuantity: favoriteItem.decQuantity,
                  sArticleID: favoriteItem.oArticle!.sArticleID as string,
                  sArticleName: favoriteItem.oArticle!.sName,
                  sQuantityUnit: favoriteItem.oArticle!.sQuantityUnitSales,
                  oArticle: favoriteItem.oArticle!,
                  emitHook: false,
                }),
              ),
            end$,
          ]),
        ),
        mergeMap(($req) => $req, 1),
        untilDestroyed(this),
        takeUntil(end),
      )
      .subscribe();
  }

  openLoginModal(): void {
    this.modal
      .addModal(LoginModalComponent)
      .pipe(
        first(),
        filter((result: string | null) => result === 'success'),
        debounceTime(500),
        switchMap(() => this.favoriteListQuery.selectActiveId()),
        tap(() => this.close()),
        switchMap((favoriteId: ID | null | undefined) =>
          this.router.navigate([this.urlHelper.localizeUrl('profile/favorites/detail'), favoriteId]),
        ),
        first(),
      )
      .subscribe();
  }

  protected readonly orderBy = orderBy;
}
