import {
  AuthService,
  createFavoriteList,
  FavoriteHttpService,
  FavoriteItemStore,
  FavoriteListStore,
  FavoriteLocalService,
  FavoriteService,
  UserService,
} from '@lobos/library-v2';
import { PaginationResponseInterface } from '@lobos/common';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { iif, Observable, of, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GelaFavoriteList } from '../../interfaces/favorites/gela-favorite-list.interface';
import { GelaFavoriteItem } from './model/gela-favorite-item.model';

@Injectable({ providedIn: 'root' })
export class GelaFavoriteService<T extends GelaFavoriteList, R extends GelaFavoriteItem> extends FavoriteService<T, R> {
  constructor(
    private _transloco: TranslocoService,
    private _translocoLocale: TranslocoLocaleService,
    private _authService: AuthService,
    private _favoriteHttpService: FavoriteHttpService<T, R>,
    private _favoriteLocalService: FavoriteLocalService<T, R>,
    private _favoriteItemStore: FavoriteItemStore<R>,
    private _favoriteListStore: FavoriteListStore<T>,
    private _userService: UserService,
  ) {
    super(
      _transloco,
      _translocoLocale,
      _authService,
      _favoriteHttpService,
      _favoriteLocalService,
      _favoriteItemStore,
      _favoriteListStore,
      _userService,
    );
  }

  override activateRecentOrCreateNew(): Observable<T> {
    const user = this._authService.getAuthUser();
    return super.getFavoriteLists().pipe(
      switchMap((lists: PaginationResponseInterface<T>) =>
        iif(
          // user already has favorites that can be activated
          () => (user ? lists.data.filter((list) => list.sUsername === user.sUserName).length > 0 : lists.total > 0),
          // true
          of(lists.data.sort((a: T, b: T) => b.dtP48EntryDate - a.dtP48EntryDate)[0]),
          // false
          this.gelaCreateEmptyList(),
        ),
      ),
      tap((list: T) => super.setActiveFavoriteId(list.gListID)),
    );
  }

  private gelaCreateEmptyList(): Observable<T> {
    return this._transloco
      .selectTranslate(
        'domain.new-list.name',
        { date: this._translocoLocale.localizeDate(Date.now(), undefined, { dateStyle: 'medium', timeStyle: 'short' }) },
        FavoriteService.TRANSLOCO_SCOPE,
      )
      .pipe(switchMap((name: string) => this.createFavoriteList(createFavoriteList<T>({ sListname: name }))));
  }
}
