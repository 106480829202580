<ng-container *transloco="let t; scope: 'profile'">
  <div
    class="js-modal-favourites js-modal fixed z-50 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gela-black opacity-64 transition-opacity" aria-hidden="true"></div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&ZeroWidthSpace;</span>
      <div
        class="relative inline-block align-bottom text-left overflow-hidden shadow-flyout transform transition-all sm:my-8 sm:align-middle sm:max-w-modal sm:w-full"
      >
        <div class="block absolute top-5 right-4 z-10">
          <button
            (click)="close()"
            class="js-close-modal p-1 leading-none rounded-default group text-primary hover:bg-primary-bright transition-all duration-200"
          >
            <span class="ifont ifont--close text-icon-sml group-hover:text-primary-dark transition-all duration-200"></span>
          </button>
        </div>
        <div class="w-full bg-white p-6 border border-primary-light rounded-20px">
          <form class="flex flex-col space-y-6" (submit)="save()">
            <p class="font-montserrat font-bold text-lg tablet:text-2xl pr-6">
              {{ favoriteList ? t("profile.favorites.favorite-modal.title.edit") : t("profile.favorites.favorite-modal.title.new") }}
            </p>
            <span *ngIf="listIsShared">{{ t("profile.favorites.favorite-modal.title.share-info") }}</span>

            <app-input
              *ngIf="form"
              [form]="form"
              name="sListname"
              [required]="true"
              [label]="t('profile.favorites.favorite-modal.form.field.title.label')"
              [hasError]="form | hasError : 'sListname'"
              [errorText]="
                form
                  | getError
                    : 'sListname'
                    : {
                        required: t('profile.favorites.favorite-modal.form.field.title.error-required')
                      }
              "
            ></app-input>

            <div class="w-full flex space-x-5 justify-end self-start pt-3.5">
              <app-button
                [text]="t('profile.favorites.favorite-modal.button.cancel')"
                (click)="close()"
                [classes]="'js-close-modal self-start bg-primary-light text-gela-black hover:text-white hover:bg-secondary'"
              ></app-button>
              <app-button
                type="submit"
                [text]="t('profile.favorites.favorite-modal.button.save')"
                [classes]="'js-delete self-start bg-secondary text-white hover:bg-gela-black'"
              ></app-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-container>
