import { Component, Input } from '@angular/core';
import { createFavoriteItem, FavoriteItemQuery, FavoriteListQuery, FavoriteService } from '@lobos/library-v2';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Article, FavoriteItem, FavoriteList } from '@lobos/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map, switchMap, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-article-favorite',
  templateUrl: './article-favorite.component.html',
  styleUrls: ['./article-favorite.component.scss'],
})
export class ArticleFavoriteComponent {
  @Input() size: 's' | 'm' | 'l' = 'm';
  @Input() quantity: number = 1;
  public sizeClasses: Record<string, string> = {
    s: 'ifont--like-small',
    m: 'ifont--like',
    l: 'ifont--like text-3xl',
  };
  public sizeClassesActive: Record<string, string> = {
    s: 'ifont--like-small-active',
    m: 'ifont--like-active',
    l: 'ifont--like-active text-3xl',
  };
  public sizeClassesButton: Record<string, string> = {
    s: 'w-6 h-6',
    m: 'w-6 h-6',
    l: 'w-8 h-8',
  };
  private articleChanged$: BehaviorSubject<Article | undefined> = new BehaviorSubject<Article | undefined>(undefined);
  isFavorite$: Observable<boolean> = this.articleChanged$.pipe(
    switchMap(() => this.favoriteListQuery.selectActiveId()),
    switchMap((id: any) => this.favoriteItemQuery.selectFavoriteItems(id!)),
    map((items: FavoriteItem[]) => {
      return !!items.find((item) => item.oArticle?.sArticleID === this.article?.sArticleID);
    }),
  );

  constructor(
    private favoriteItemQuery: FavoriteItemQuery,
    private favoriteService: FavoriteService<FavoriteList, FavoriteItem>,
    private favoriteListQuery: FavoriteListQuery,
  ) {}

  private _article: Article | undefined;

  get article(): Article | undefined {
    return this._article;
  }

  @Input() set article(value: Article | undefined) {
    if (value) {
      this._article = value;
      this.articleChanged$.next(value);
    }
  }

  addArticleToFavoriteList(article: Article, e: Event) {
    e.stopPropagation();
    const activeFavoriteListId = this.favoriteListQuery.getActiveId();
    const newFavoriteItem = createFavoriteItem({
      gListID: activeFavoriteListId as any,
      oArticle: article as any,
      decQuantity: this.quantity || article.decQuantityPackage || 1,
    });
    this.favoriteService
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .createFavoriteItem(newFavoriteItem, activeFavoriteListId!)
      .pipe(take(1), untilDestroyed(this))
      .subscribe();
  }

  removeArticleFromFavoriteList(article: Article, e: Event) {
    e.stopPropagation();
    this.favoriteItemQuery
      .selectEntity(
        (item: FavoriteItem) =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          item.oArticle?.sArticleID === article.sArticleID && item.gListID === this.favoriteListQuery.getActiveId(),
      )
      .pipe(
        first(),
        switchMap((favoriteItem: FavoriteItem | undefined) =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.favoriteService.deleteFavoriteItem(favoriteItem!),
        ),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
