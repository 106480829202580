<ng-container *transloco="let t; scope: 'header'">
  <ng-container
    *ngIf="{
      isLoggedIn: isLoggedIn$ | async,
      favoriteLists: favoriteLists$ | async,
      activeFavorite: activeFavoriteList$ | async,
      activeItems: activeFavoriteItems$ | async
    } as context"
  >
    <div *ngIf="!mobile" appClickOutside (clickOutside)="close()">
      <button
        (click)="open()"
        class="js-show-info-btn p-2 leading-none rounded-default group hover:bg-primary-bright transition-all duration-200"
      >
        <span
          class="ifont ifont--like text-3xl text-primary group-hover:text-primary-dark transition-all duration-200 pointer-events-none"
        ></span>
        <span
          *ngIf="context.activeItems?.length"
          class="bg-gela-black border-2 border-white text-white text-small font-montserrat font-bold rounded-full w-4 h-4 flex items-center justify-center absolute top-3 right-0 pointer-events-none"
          >&nbsp;&nbsp;</span
        >
      </button>
      <div
        [class.hidden]="!showFlyout"
        class="js-info absolute bg-white border border-primary-light rounded-flyout shadow-flyout translate-y-0 md:top-full -right-3 z-50 overflow-hidden"
      >
        <div class="max-h-500px md:max-h-flyout-header md:min-h-flyout-header p-3">
          <div [appLoading]="isLoading$ | async" class="md:w-614px bg-white">
            <div class="sm:px-6 sm:py-3 fixed left-0 top-3 w-full z-20 bg-white">
              <div class="flex justify-between mb-4">
                <p class="font-montserrat font-bold text-lg">{{ t("header.favorites.title") }}</p>
                <button (click)="close()" class="js-close-header-flyout transition-all duration-200 hover:text-primary">
                  <span class="ifont ifont--close text-3xl pointer-events-none"></span>
                </button>
              </div>
              <div *ngIf="context.activeFavorite as favoriteList" class="flex items-center justify-between">
                <div class="min-w-5/12 inline-block">
                  <app-button-dropdown
                    *ngIf="context.favoriteLists as favorites"
                    [options]="favorites"
                    [value]="favoriteList.gListID.toString()"
                    (valueChange)="setActiveFavoriteList($event)"
                    [showValue]="true"
                    [hasMaxHeight]="true"
                  ></app-button-dropdown>
                </div>
                <div class="flex space-x-4 items-start ml-6">
                  <a
                    (click)="close()"
                    [routerLink]="'profile/favorites' | localizeUrlPathAsync | async"
                    class="text-sm font-bold hover:text-primary leading-tight"
                  >
                    <span class="ifont ifont--settings text-icon"></span>
                  </a>
                  <button
                    (click)="addFavoriteList()"
                    class="js-open-favourites-modal text-left text-sm font-bold hover:text-primary leading-tight"
                  >
                    <span class="ifont ifont--add text-icon pointer-events-none"></span>
                  </button>
                </div>
              </div>
            </div>
            <div [ngClass]="context.isLoggedIn ? 'h-28 2xl:h-32' : 'h-14 2xl:h-16'">&nbsp;</div>
            <ng-container *ngIf="context.activeItems?.length; else empty">
              <div
                class="sm:pl-3 pt-2 px-3 pb-16 max-h-favourites md:max-h-favourites-md scrollbar scrollbar--favourites overflow-x-hidden overflow-y-auto"
              >
                <div class="flex flex-col divide-y divide-primary-light">
                  <app-favorites-flyout-item
                    *ngFor="let item of context.activeItems | orderBy : ['shtItemID']; first as first"
                    [item]="item"
                    [first]="first"
                    [cutToSize]="true"
                  ></app-favorites-flyout-item>
                </div>
              </div>
              <div class="w-full z-20 bg-white bottom-0 left-0 fixed flex">
                <div class="px-4 sm:px-6 py-4">
                  <div class="w-auto">
                    <app-gela-button
                      *ngIf="context.isLoggedIn"
                      (click)="addAllToCart()"
                      [look]="'secondary'"
                      [text]="t('header.favorites.add-all-to-cart')"
                      [size]="'small'"
                      [type]="'button'"
                      [appLoading]="isLoading$ | async"
                      [loading]="isLoading$ | async"
                    ></app-gela-button>
                    <app-gela-button
                      *ngIf="!context.isLoggedIn"
                      (click)="openLoginModal()"
                      [look]="'primary'"
                      [text]="t('header.favorites.login')"
                      [size]="'small'"
                      [type]="'button'"
                      [appLoading]="isLoading$ | async"
                      [loading]="isLoading$ | async"
                    ></app-gela-button>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #empty>
              <div class="w-350px p-6">
                <p class="font-montserrat font-medium text-small">{{ t("header.favorites.empty-message") }}</p>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="mobile" class="js-mobile-favourites min-h-full bg-white px-5 pt-5">
      <div *ngIf="!context.isLoggedIn" class="mt-11 w-full">
        <p class="font-montserrat font-medium text-small">
          {{ t("header.favorites.anonymous") }}
        </p>
      </div>
      <div *ngIf="context.isLoggedIn" class="mt-11">
        <div class="w-full">
          <div class="w-full z-20">
            <div class="flex justify-between mb-4">
              <p class="text-xl font-montserrat font-bold">{{ t("header.favorites.title") }}</p>
              <div class="flex space-x-4 items-start">
                <a
                  (click)="close()"
                  [routerLink]="'profile/favorites' | localizeUrlPathAsync | async"
                  class="text-sm font-bold hover:text-primary leading-tight"
                >
                  <span class="ifont ifont--settings text-icon"></span>
                </a>
                <button
                  (click)="addFavoriteList()"
                  class="js-open-favourites-modal text-left text-sm font-bold hover:text-primary leading-tight"
                >
                  <span class="ifont ifont--add text-icon pointer-events-none"></span>
                </button>
              </div>
            </div>
            <div class="w-full inline-block">
              <app-button-dropdown
                *ngIf="context.activeFavorite"
                [options]="context.favoriteLists!"
                [value]="context.activeFavorite.gListID.toString()"
                (valueChange)="setActiveFavoriteList($event)"
                [showValue]="true"
              ></app-button-dropdown>
            </div>
          </div>
          <ng-container *ngIf="context.activeItems?.length; else empty">
            <div [appLoading]="isLoading$ | async" class="md:mb-12 xl:pt-6 pb-3 bg-white my-6">
              <div class="flex flex-col divide-y divide-primary-light">
                <app-favorites-flyout-item
                  *ngFor="let item of context.activeItems | orderBy : ['shtItemID']"
                  [item]="item"
                ></app-favorites-flyout-item>
              </div>
            </div>
            <div [appLoading]="isLoading$ | async" class="w-full flex pb-8" *ngIf="context.activeItems && context.activeItems.length">
              <div class="w-auto">
                <app-gela-button
                  (click)="addAllToCart()"
                  [look]="'secondary'"
                  [text]="t('header.favorites.add-all-to-cart')"
                  [size]="'small'"
                  [type]="'button'"
                  [appLoading]="isLoading$ | async"
                  [loading]="isLoading$ | async"
                ></app-gela-button>
              </div>
            </div>
          </ng-container>
          <ng-template #empty>
            <div class="w-350px p-6">
              <p class="font-montserrat font-medium text-small">{{ t("header.favorites.empty-message") }}</p>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
