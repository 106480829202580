<ng-container *ngIf="article">
  <div aria-labelledby="modal-title" aria-modal="true" class="js-modal-article js-modal fixed z-50 inset-0 overflow-y-auto" role="dialog">
    <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div aria-hidden="true" class="fixed inset-0 bg-gela-black opacity-64 transition-opacity"></div>
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span aria-hidden="true" class="hidden sm:inline-block sm:align-middle sm:h-screen">&ZeroWidthSpace;</span>
      <div
        class="relative inline-block align-bottom text-left shadow-flyout transform transition-all sm:my-8 sm:align-middle sm:max-w-modal sm:w-full"
      >
        <div class="block absolute top-5 right-4 z-10">
          <button
            (click)="close()"
            class="js-close-modal p-1 leading-none rounded-default group text-primary hover:bg-primary-bright transition-all duration-200"
          >
            <span class="ifont ifont--close text-icon-sml group-hover:text-primary-dark transition-all duration-200"></span>
          </button>
        </div>
        <div *transloco="let t; scope: 'catalog'" class="w-full bg-white p-6 border border-primary-light rounded-20px js-tile">
          <div class="flex">
            <app-image [image]="article | articleMainImage" classes="w-181px h-181px object-contain object-center"></app-image>

            <div class="pl-5">
              <p class="font-montserrat font-bold text-small mt-1 pr-7">
                {{
                  t("catalog.article-mini-card.sku", {
                    sku: article.sArticleID
                  })
                }}
              </p>
              <p class="w-10/12 pr-4 mt-2 mb-3 leading-tight">
                {{ article.sDescription }}
              </p>
              <!-- price default start -->
              <div class="flex items-center last:mb-7">
                <app-article-price [article]="article" [priceScaleType]="'minicard'" [size]="'m'">
                  <app-article-availability [article]="article" [showButton]="true"></app-article-availability>
                </app-article-price>
              </div>
              <!-- price end -->
              <div class="flex items-center mt-auto">
                <app-add-to-cart [article]="article" (quantityChanged)="quantityChanged($event)"></app-add-to-cart>

                <div class="ml-6">
                  <!-- like start -->
                  <app-article-favorite [article]="article" [quantity]="quantity" [size]="'l'"></app-article-favorite>

                  <!-- like end -->
                </div>
              </div>
            </div>
          </div>
          <div class="flex mt-5">
            <div class="-ml-1 flex">
              <div class="relative mr-1.5 last:mr-0 leading-none">
                <app-article-ordered [article]="article" flyoutPosition="top"></app-article-ordered>
              </div>
              <div class="relative z-20 mr-0.5 last:mr-0 leading-none translate-y-px">
                <app-preferred-commission [item]="article"></app-preferred-commission>
              </div>
              <div class="relative mr-1.5 last:mr-0 leading-none">
                <app-flyout [closable]="false" [disableOnTouch]="true">
                  <ng-container trigger>
                    <button (click)="openDownloadModal(article)" class="js-download group">
                      <span
                        class="ifont ifont--download text-icon text-primary -translate-y-0.5 group-hover:text-gela-black transition-all duration-200"
                      ></span>
                    </button>
                    <ng-content></ng-content>
                  </ng-container>
                  <div flyout>{{ t("catalog.article-mini-card.tooltip-datasheet") }}</div>
                </app-flyout>
              </div>
              <div class="relative mr-1.5 last:mr-0 leading-none">
                <!-- notice start -->
                <app-article-note [article]="article" [mini]="true"></app-article-note>
                <!-- notice end -->
              </div>
            </div>
            <!--            <div class="ml-auto">-->
            <!--              &lt;!&ndash; promotion start &ndash;&gt;-->
            <!--              <div class="relative inline-block">-->
            <!--                <button-->
            <!--                  class="js-show-flyout px-2.5 py-5px pr-8 bg-gela-black leading-tight flex items-center text-white relative rounded-default"-->
            <!--                >-->
            <!--                  <span class="uppercase text-small font-montserrat font-bold">Promotion</span>-->
            <!--                  <span class="ifont ifont&#45;&#45;info text-3xl absolute right-1 top-1/2 -translate-y-1/2"></span>-->
            <!--                </button>-->
            <!--                &lt;!&ndash; @lobos to show flyout remove class hidden &ndash;&gt;-->
            <!--                &lt;!&ndash; @lobos markup for article-modal &ndash;&gt;-->
            <!--                <div-->
            <!--                  class="js-flyout hidden px-5 pt-6 pb-5 absolute -top-7 bg-white -left-2 translate-x-2 -translate-x-full border border-primary-light z-30 rounded-flyout shadow-flyout bg-white w-36 md:w-60"-->
            <!--                >-->
            <!--                  <img src="assets/images/layout/flyout_arrow.svg" alt="" class="absolute top-8 right-0 translate-x-full rotate-180" />-->

            <!--                  <p class="font-montserrat font-bold text-small mb-3.5 pr-5">Promotion</p>-->
            <!--                  <p class="text-small mt-2.5">Lorem ipsum dolor sit amet, consectetur adipiscing elit ut.</p>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              &lt;!&ndash; promotion end &ndash;&gt;-->
            <!--            </div>-->
          </div>
          <div class="border-t border-primary-light mt-18px mb-12">
            <app-property-list
              [sort]="false"
              [properties]="$any(article.oFeatures) | orderBy : 'tableSort' | articleFeaturesToKeyValue"
            ></app-property-list>
          </div>
          <a
            (click)="close()"
            [queryParams]="article.sArticleID!.toString() | urlFilterParams"
            [routerLink]="'/article/' + article.sArticleID | localizeUrlPathAsync | async"
            class="w-full inline-block text-center font-montserrat font-bold px-6 py-3.5 border-2 border-primary-light rounded-button transition-all duration-200 bg-white hover:text-white hover:bg-secondary hover:border-secondary"
          >
            {{ t("catalog.article-preview-modal.to-article") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
