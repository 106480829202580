import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FavoriteItem, FavoriteList } from '@lobos/common';
import { createFavoriteList, FavoriteService } from '@lobos/library-v2';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { iif, of } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';

export interface FavoriteListData {
  favoriteList: FavoriteList | null | undefined;
  listIsShared?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-add-edit-favorite-modal',
  templateUrl: './add-edit-favorite-modal.component.html',
  styleUrls: ['./add-edit-favorite-modal.component.scss'],
})
export class AddEditFavoriteModalComponent extends SimpleModalComponent<FavoriteListData, any> implements FavoriteListData, OnInit {
  public favoriteList: FavoriteList | null | undefined;
  public listIsShared: boolean | undefined;
  public errorText: string | undefined;
  public form: FormGroup<{ sListname: FormControl<string> }> = this.fb.nonNullable.group({ sListname: ['', Validators.required] });

  constructor(
    private modal: SimpleModalService,
    private fb: FormBuilder,
    private favoriteService: FavoriteService<FavoriteList, FavoriteItem>,
  ) {
    super();
  }

  ngOnInit() {
    if (this.favoriteList) {
      this.form.patchValue({ ...this.favoriteList });
    }

    this.form.valueChanges
      .pipe(
        tap(() => (this.errorText = undefined)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  save(): void {
    this.errorText = undefined;
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }

    of(this.form.value)
      .pipe(
        switchMap((formValues: Record<string, unknown>) =>
          iif(
            () => !!this.favoriteList,
            // true
            this.favoriteService.updateFavoriteList({ ...this.favoriteList!, ...formValues }),
            // false
            this.favoriteService
              .createFavoriteList({ ...createFavoriteList({}), ...formValues })
              .pipe(tap((list) => this.favoriteService.setActiveFavoriteId(list.gListID))),
          ),
        ),
        first(),
        untilDestroyed(this),
      )
      .subscribe(
        () => this.close(),
        (e) => (this.errorText = e.error.text),
      );
  }
}
