import { Injectable } from '@angular/core';
import { Article, Product, ReferenceLink, Ressource } from '@lobos/common';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { environment } from '../../../environments/environment';
import { GelaCatalog } from './model/gela-catalog';

export interface ImageGalleryItem {
  id: string;
  image: string;
}

@Injectable({
  providedIn: 'root',
})
export class ImageHelperService {
  public getCatalogMainImage(catalog: GelaCatalog): CloudinaryImage | null {
    const image: Ressource | undefined = catalog.oResources.find(
      (resource: Ressource) => resource.shtResourceGroupIDInternal === environment.images.categoryImage,
    );
    if (!image) {
      return this.returnFallbackImage();
    }
    return this.buildCloudinaryUrl(this.buildImageUrl(image!.sResourceKey));
  }

  public getProductImages(product: Product): ImageGalleryItem[] {
    let images: ImageGalleryItem[] = [];
    const resources: Ressource[] = product.oResources.filter((resource: Ressource) =>
      [...environment.images.productDetailImages].includes(resource.shtResourceGroupIDInternal),
    );

    images = resources
      .sort((a, b) => (a.lngItemID > b.lngItemID ? 1 : b.lngItemID > a.lngItemID ? -1 : 0))
      .map((resource: Ressource) => ({
        id: resource.sResourceName,
        image: this.buildCloudinaryUrl(this.buildImageUrl(resource.sResourceKey)).toURL(),
      }));

    if (images.length === 0 && product.oArticles) {
      const articleImage = product.oArticles[0].oReferenceLink.find((link: ReferenceLink) => link.sTypeCode === 'BILD');
      if (articleImage) {
        images.push({
          id: product.oArticles[0].sArticleID.toString(),
          image: this.buildCloudinaryUrl(this.buildImageUrl(articleImage.sLinkReference)).toURL(),
        });
      } else {
        images.push(this.addFallbackToGallery());
      }
    }

    return images;
  }

  public getProductMainImage(product: Product): CloudinaryImage | null {
    const images: Ressource[] | undefined = product.oResources?.filter(
      (resource: Ressource) => resource.shtResourceGroupIDInternal === environment.images.categoryImage,
    );

    const image: Ressource = images?.sort((a, b) => (a.lngItemID > b.lngItemID ? 1 : b.lngItemID > a.lngItemID ? -1 : 0))[0];

    if (!image) {
      return this.returnFallbackImage();
    }
    return this.buildCloudinaryUrl(this.buildImageUrl(image!.sResourceKey));
  }

  public getArticleMainImage(article: Article): CloudinaryImage | null {
    const image: ReferenceLink | Ressource | undefined = article.oReferenceLink
      ?.filter((link: ReferenceLink) => link.sTypeCode === 'Image')
      .sort((a, b) => a.lngLinkProgram - b.lngLinkProgram)[0];

    if (image) {
      return this.buildCloudinaryUrl(this.buildImageUrl(image.sLinkReference));
    }

    if (article.oProductInfo?.length) {
      return this.getProductMainImage(article.oProductInfo[0]);
    } else {
      return this.getDefaultCloudinaryImage();
    }
  }

  public getArticleImages(article: Article): ImageGalleryItem[] {
    let images: ImageGalleryItem[] = [];
    const referenceLinks: ReferenceLink[] = article.oReferenceLink?.filter((link: ReferenceLink) => link.sTypeCode === 'Image');

    images = referenceLinks
      ?.sort((a, b) => a.lngLinkProgram - b.lngLinkProgram)
      .map((link: ReferenceLink) => {
        let id = link.sDescription;
        if ((id === 'Bildlegende_' || !id) && !!article.oProductInfo[0]) {
          id = article.oProductInfo[0].oResources.filter((resource: Ressource) =>
            [...environment.images.productDetailImages].includes(resource.shtResourceGroupIDInternal),
          )[0].sResourceName;
        }
        return {
          id: id,
          image: this.buildCloudinaryUrl(this.buildImageUrl(link.sLinkReference)).toURL(),
        };
      });

    if (!images?.length && article.oProductInfo[0]) {
      return this.getProductImages(article.oProductInfo[0]);
    }
    if (!images.length && !article.oProductInfo[0]) {
      images.push(this.addFallbackToGallery());
    }
    return images;
  }

  public getDefaultCloudinaryImage(): CloudinaryImage {
    return this.buildCloudinaryUrl(`${environment.images.cloudinary.fetchImageUrl}/placeholder.png`);
  }

  public buildImageUrl(imagePath: string, fileExtension: string = 'jpg'): string {
    return `${environment.images.cloudinary.fetchImageUrl}/${imagePath}.${fileExtension}`;
  }

  public buildCloudinaryUrl(imageUrl: string): CloudinaryImage {
    return new CloudinaryImage(
      imageUrl,
      { cloudName: environment.images.cloudinary.cloudName },
      { secureDistribution: environment.images.cloudinary.secureDistribution },
    ).setDeliveryType('fetch');
  }

  getResources(resources: Ressource[]): Ressource[] {
    return resources.filter((resource: Ressource) =>
      [...environment.images.productDetailImages].includes(resource.shtResourceGroupIDInternal),
    );
  }

  getFirstMatchingResource(resources: Ressource[]): Ressource | undefined {
    return resources.find((resource: Ressource) =>
      [...environment.images.productDetailImages].includes(resource.shtResourceGroupIDInternal),
    );
  }

  getProductManufacturerLogo(resources: Ressource[]): CloudinaryImage | null {
    const image = resources.find((resource: Ressource) => resource.shtResourceGroupIDInternal === environment.images.manufacturerLogo);

    if (!image) {
      return null;
    }

    return this.buildCloudinaryUrl(this.buildImageUrl(image.sResourceKey, 'png'));
  }

  getArticleManufacturerLogo(referenceLinks: ReferenceLink[]): CloudinaryImage | null {
    const image = referenceLinks.find((link: ReferenceLink) => link.sTypeCode === 'Brand');

    if (!image) {
      return null;
    }

    return this.buildCloudinaryUrl(this.buildImageUrl(image.sLinkReference, 'png'));
  }

  addFallbackToGallery(): ImageGalleryItem {
    return {
      id: '',
      image: this.buildCloudinaryUrl(`${environment.images.cloudinary.fetchImageUrl}/placeholder.jpg`).toURL(),
    };
  }

  returnFallbackImage(): CloudinaryImage {
    return this.buildCloudinaryUrl(`${environment.images.cloudinary.fetchImageUrl}/placeholder.png`);
  }
}
