<!-- flyout card start -->
<div *transloco="let t; scope: 'header'" class="js-tile bg-white relative z-10">
  <a
    (click)="linkClicked.emit($event)"
    [routerLink]="['article' | localizeUrlPathAsync | async, article.sArticleID]"
    class="absolute inset-0 rounded-20px"
  ></a>
  <div class="flex tablet:block z-30 mb-2 tablet:mb-0">
    <!-- @lobos if there is the auf mass button if needs the class left-10 otherwise it needs class left-0 -->
    <div class="tablet:absolute desktop-grid:top-14 2xl:top-0 xl:left-0 inline-block">
      <app-article-shop-info [article]="article" placement="inline"></app-article-shop-info>
    </div>
  </div>
  <div class="flex">
    <app-image [image]="article | articleMainImage" classes="w-20 tablet:w-32 h-20 tablet:h-32 object-contain object-center"></app-image>

    <div class="block basis-flyout-card-sml tablet:basis-flyout-card pl-3">
      <div class="w-full flex -mt-0.5 absolute -top-6 2xl:top-0 left-0 2xl:relative z-20">
        <p class="text-small truncate w-flyout-cat">{{ article.sName }}</p>
        <div class="ml-auto flex items-center md:-mr-1.5 -translate-y-1">
          <div class="relative mr-1.5 last:mr-0 leading-none">
            <app-article-ordered [article]="article"></app-article-ordered>
          </div>
          <div class="relative mr-1.5 last:mr-0 leading-none">
            <app-article-note [article]="article"></app-article-note>
          </div>
          <div class="relative z-20 mr-0.5 last:mr-0 leading-none translate-y-px">
            <app-preferred-commission [item]="article"></app-preferred-commission>
          </div>
          <div class="hidden tablet:block relative mr-1.5 last:mr-0 leading-none">
            <app-article-favorite [article]="article" [quantity]="quantity" size="s"></app-article-favorite>
          </div>
        </div>
      </div>
      <div class="-mt-1.5 2xl:-mt-0.5">
        <span class="text-small font-montserrat font-bold leading-tight">
          {{
            t("header.search.item.sku", {
              sku: article.sArticleID
            })
          }}
        </span>
        <p class="line-clamp-2 tablet:line-clamp-1" [innerHTML]="article.sDescription"></p>
      </div>
      <div class="tablet:flex items-start mt-5">
        <div class="flex flex-col -mb-4 -mt-18px z-10">
          <div class="flex flex-row">
            <!-- price flyout start -->
            <app-article-price [article]="article" [size]="'m'">
              <app-article-availability [article]="article" [showButton]="true"></app-article-availability>
            </app-article-price>
            <!-- price flyout end -->
          </div>
        </div>
        <div class="ml-0 tablet:ml-auto flex items-center mt-7 tablet:-mt-2.5 -mr-1.5">
          <app-add-to-cart [article]="article" (quantityChanged)="quantityChanged($event)"></app-add-to-cart>
          <div class="block tablet:hidden relative ml-4 leading-none">
            <app-article-favorite [article]="article" [quantity]="quantity"></app-article-favorite>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- flyout card end -->
