import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { AuthService, FavoriteItemStore, FavoriteService } from '@lobos/library-v2';
import { iif, Observable, of } from 'rxjs';
import { filter, finalize, first, map, switchMap, tap } from 'rxjs/operators';
import { FavoriteItem, FavoriteList, PaginationResponseInterface, User } from '@lobos/common';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FavoriteHelperService {
  constructor(
    private favoriteService: FavoriteService<FavoriteList, FavoriteItem>,
    private authService: AuthService,
    private http: HttpClient,
    private store: FavoriteItemStore,
  ) {}

  public initializeFavorites(favoriteId?: ID): Observable<FavoriteList[]> {
    return of(favoriteId).pipe(
      switchMap((id: ID | undefined) =>
        iif(
          () => !id,
          // true
          this.authService.authUser$.pipe(
            tap(() => this.favoriteService.clearFavoriteLists()),
            switchMap((user: User | undefined) => this.favoriteService.setActiveFavorite(user!)),
          ),
          // false
          of(favoriteId),
        ),
      ),
      filter((id: ID | undefined) => !!id),
      switchMap((id: ID | undefined) =>
        this.favoriteService.getFavoriteItems(id!).pipe(map((res: PaginationResponseInterface<FavoriteItem>) => res.data)),
      ),
      switchMap(() => this.favoriteService.getFavoriteLists().pipe(map((res: PaginationResponseInterface<FavoriteList>) => res.data))),
    );
  }

  public updateItemOrder(favoriteItems: FavoriteItem[]) {
    let updates: { shtItemID: number; articleID: string | number | undefined }[] = [];
    updates = favoriteItems.map((item, index) => ({
      shtItemID: index,
      articleID: item.oArticle?.sArticleID,
    }));

    return of(undefined).pipe(
      tap(() => this.store.setLoading(true)),
      switchMap(() =>
        this.http
          .put<PaginationResponseInterface<FavoriteItem>>('api/favorites/' + favoriteItems[0].gListID + '/items/sort', { updates: updates })
          .pipe(first()),
      ),
      tap((data: PaginationResponseInterface<FavoriteItem>) => this.store.upsertMany(data.data)),
      finalize(() => this.store.setLoading(false)),
    );
  }
}
