import { Injectable } from '@angular/core';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { environment } from '../../../environments/environment';
import { fill } from '@cloudinary/url-gen/actions/resize';

@Injectable()
export class CmsImageService {
  static buildCloudinaryUrl(imageUrl: string, params?: { width: number; height: number }): CloudinaryImage {
    const image = new CloudinaryImage(
      imageUrl,
      { cloudName: environment.images.cloudinary.cloudName },
      { secureDistribution: environment.images.cloudinary.secureDistribution },
    ).setDeliveryType('fetch');
    if (params) {
      image.resize(fill().width(params.width).height(params.height));
    }

    return image;
  }
}
